module.exports = function () {
    // session sharing logic

    $('body').on('click', '.js-handle-session-sharing', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        $.ajax({
            url: url,
            success: function (data) {
                if (!data.success) {
                    // if cookies are not returned then simpley redirect user to the url
                    window.location.href = data.redirectURL;
                } else {
                    // if we get cookies send a post request to a url for other site to set cookies
                    var redirect = $('<form class="d-none">')
                        .appendTo(document.body)
                        .attr({
                            method: 'POST',
                            action: data.redirectURL
                        });

                    $('<input>')
                        .appendTo(redirect)
                        .attr({
                            name: 'cookies',
                            value: JSON.stringify(data.cookies)
                        });
                    $('<input>')
                        .appendTo(redirect)
                        .attr({
                            name: 'currency',
                            value: data.currencyCode
                        });

                    redirect.submit();
                }
            },
            error: function () {
                window.location.reload();
            }
        });
    });
};
